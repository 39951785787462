import axios from '@/plugins/axios';

class SpentOfDeviceApi {
  static getDevices(
    page = 1,
    limit = 10,
    channelId = NaN,
    storeId = NaN,
    productNo = NaN,
    produceNo = NaN,
    startDate = '',
    endDate = '',
  ) {
    return axios({
      url: '/api/stat/product/train/page',
      data: {
        page,
        limit,
        channel_id: channelId,
        store_id: storeId,
        product_no: productNo,
        produce_no: produceNo,
        start_date: startDate,
        end_date: endDate,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询设备使用记录列表',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/stat/product/train/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default SpentOfDeviceApi;
