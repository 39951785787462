export const SPENT_OF_DEVICE_COLUMNS = [
  // {
  //   title: '设备编号',
  //   key: 'product_no',
  //   minWidth: 150,
  // },
  {
    title: '生产编号',
    key: 'produce_no',
    minWidth: 150,
  },
  {
    title: '渠道名称',
    key: 'channel_name',
    minWidth: 100,
  }, {
    title: '门店名称',
    key: 'store_name',
    minWidth: 100,
  },
];

export const SELECTABLE_DATE_RANGE = {
  LAST_MONTH: 'lastMonth',
  THIS_MONTH: 'thisMonth',
  PREVIOUS_SIX_MONTHS: 'previousSixMonths',
  LAST_YEAR: 'lastYear',
};

export const SELECTABLE_DATE_RANGE_TEXT = {
  [SELECTABLE_DATE_RANGE.LAST_MONTH]: '上个月',
  [SELECTABLE_DATE_RANGE.THIS_MONTH]: '本月',
  [SELECTABLE_DATE_RANGE.PREVIOUS_SIX_MONTHS]: '最近半年',
  [SELECTABLE_DATE_RANGE.LAST_YEAR]: '最近一年',
};
