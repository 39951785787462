<template>
  <div class="spent-of-device">
    <app-query-bar
      v-model="queryData"
      :queryConfig="queryConfig"
      @onChange="onQueryChange"
      @onSearch="fetchDevices">
      <ButtonGroup
        slot="dateRangeQuickSelector">
        <Button
          :type="highlightField === key ? 'primary' : 'default'"
          v-for="(text, key) in SELECTABLE_DATE_RANGE_TEXT"
          :key="key"
          @click="() => onQuickSelectDateRange(key)">
          {{text}}
        </Button>
      </ButtonGroup>
    </app-query-bar>
    <app-export-bar
      @onExport="onExport"
      @onExportAll="onExportAll" />
    <app-data-table
      :columns="SPENT_OF_DEVICE_COLUMNS"
      :data="devices"
      v-model="queryData"
      @onPageConfigChange="fetchDevices" />
  </div>
</template>

<script>
import moment from 'moment';
import {
  SPENT_OF_DEVICE_COLUMNS,
  SELECTABLE_DATE_RANGE,
  SELECTABLE_DATE_RANGE_TEXT,
} from './constants';
import SpentOfDeviceApi from './spent-of-device.api';
import ChannelManageApi from '../../business/channel-manage/channel-manage.api';
import DataTransferUtil from '../../../utils/data-transfer.util';
import StoreManageApi from '../../business/store-manage/store-manage.api';
import { USER_TYPE } from '../../login/constants';

export default {
  name: 'spent-of-device',
  computed: {
    userType() {
      return this.$store.getters.userType;
    },
    queryConfig() {
      return [
        {
          fieldName: 'channelId',
          name: '渠道',
          type: 'select',
          show: this.userType === USER_TYPE.OUTLET,
          options: this.queryConfigChannelOptions,
        },
        {
          fieldName: 'storeId',
          name: '门店',
          type: 'select',
          options: this.queryConfigStoreOptions,
        },
        // {
        //   fieldName: 'productNo',
        //   name: '设备编号',
        // },
        {
          fieldName: 'produceNo',
          name: '生产编号',
        },
        {
          fieldName: 'startDate',
          name: '开始时间',
          type: 'month',
        },
        {
          fieldName: 'endDate',
          name: '结束时间',
          type: 'month',
        },
        {
          type: 'slot',
          slot: 'dateRangeQuickSelector',
        },
      ];
    },
    SPENT_OF_DEVICE_COLUMNS() {
      const dynamicColumns = this.devices.length !== 0
        ? this.devices[0].columns.map((col, index) => ({
          title: col.name,
          key: col.name,
          minWidth: 100,
          render: (h, params) => h('div', {
            domProps: {
              innerText: params.row.columns[index].count,
            },
          }),
        }))
        : [];

      return [...SPENT_OF_DEVICE_COLUMNS, ...dynamicColumns];
    },
  },
  data() {
    return {
      SELECTABLE_DATE_RANGE_TEXT,
      highlightField: null,
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        channelId: '',
        storeId: '',
        productNo: '',
        produceNo: '',
        startDate: '',
        endDate: '',
      },
      devices: [],
      queryConfigChannelOptions: [],
      queryConfigStoreOptions: [],
    };
  },
  created() {
    this.onQuickSelectDateRange(SELECTABLE_DATE_RANGE.THIS_MONTH);
    this.fetchDevices();
    this.fetchQueryChannelOptions();
    this.fetchQueryStoreOptions();
  },
  methods: {
    async fetchDevices() {
      const {
        page, limit, channelId, storeId, productNo, produceNo, startDate, endDate,
      } = this.queryData;

      const {
        count = 0,
        results = [],
      } = await SpentOfDeviceApi.getDevices(
        page,
        limit,
        channelId,
        storeId,
        productNo,
        produceNo,
        startDate,
        endDate,
      );
      this.devices = results;
      this.$set(this.queryData, 'count', count);
    },
    async fetchQueryChannelOptions() {
      if (this.userType === USER_TYPE.OUTLET) {
        return;
      }

      const options = await ChannelManageApi.getChannelsByChannelName();
      this.queryConfigChannelOptions = DataTransferUtil.originTransfer2Options(options);
    },
    async fetchQueryStoreOptions() {
      const options = await StoreManageApi.getStoresByStoreNameOrChannelId();
      this.queryConfigStoreOptions = DataTransferUtil.originTransfer2Options(options);
    },
    onQueryChange(_, fieldName) {
      if (fieldName === 'startDate' || fieldName === 'endDate') {
        this.highlightField = null;
      }
    },
    onQuickSelectDateRange(key) {
      switch (key) {
        case SELECTABLE_DATE_RANGE.LAST_MONTH:
          this.queryData.startDate = moment()
            .subtract('1', 'months')
            .format('YYYY-MM');
          this.queryData.endDate = this.queryData.startDate;
          break;
        case SELECTABLE_DATE_RANGE.THIS_MONTH:
          this.queryData.startDate = moment()
            .format('YYYY-MM');
          this.queryData.endDate = this.queryData.startDate;
          break;
        case SELECTABLE_DATE_RANGE.PREVIOUS_SIX_MONTHS:
          this.queryData.startDate = moment()
            .subtract('6', 'months')
            .format('YYYY-MM');
          this.queryData.endDate = moment()
            .format('YYYY-MM');
          break;
        case SELECTABLE_DATE_RANGE.LAST_YEAR:
          this.queryData.startDate = moment()
            .subtract('1', 'years')
            .format('YYYY-MM');
          this.queryData.endDate = moment()
            .format('YYYY-MM');
          break;
        default:
          break;
      }
      this.highlightField = key;
    },
    async onExport() {
      const {
        page,
        limit,
        channelId,
        storeId,
        productNo,
        produceNo,
        startDate,
        endDate,
      } = this.queryData;
      const key = await SpentOfDeviceApi.export({
        page,
        limit,
        channel_id: channelId,
        store_id: storeId,
        product_no: productNo,
        produce_no: produceNo,
        start_date: startDate,
        end_date: endDate,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async  onExportAll() {
      const key = await SpentOfDeviceApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
  },
};
</script>

<style scoped>

</style>
